<template>
  <div>
    <div
      v-permission="'products.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="products"
      order_column="name">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('product.name') }}</vs-th>
        <vs-th key="status" sort-key="status">{{ $t('product.status') }}</vs-th>
        <vs-th key="price" sort-key="price">{{ $t('product.price') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="50%" key="name">
            <div> {{ tr.name }}</div>
          </vs-td>
          <vs-td width="15%" key="name">
            <vs-chip transparent :color="getStatusColor(_.get(tr, 'status'))" class="text-base">{{formatStatus(_.get(tr, 'status')) }}</vs-chip>
          </vs-td>
          <vs-td width="20%" key="price">
            <span> {{formatValue(_.get(tr, 'price')) }}</span>
          </vs-td>
          <vs-td width="10%">
            <div class="flex flex-row gap-1">
              <feather-icon
                v-permission="'products.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="props.edit(props.data[indextr].id)"/>
              <feather-icon
                v-permission="'products.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="props.destroy(props.data[indextr])"/>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>

import ProductService from '@/services/api/ProductService'

import { currencyFormatter } from '@/util/Util'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/products/create')
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    formatStatus(stt){
      return this.$t('product.status_.'+stt)
    },
    getStatusColor(stt){
      switch (stt) {
        case ('CR'):
          return 'default'
          break;
        case ('ED'):
          return 'warning'
          break;
        case ('RE'):
          return 'success'
        case ('DI'):
          return 'danger'
          break;
        default:
          break;
      }
    }
  },
  beforeMount() {
    this.service = ProductService.build(this.$vs)
  }
}
</script>
